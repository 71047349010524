<template>
  <div>
    <a-card title="入库通知单详情">
      <!-- <a-button slot="extra" type="primary" style="margin-right: 8px" ghost v-print="'#printContent'">
        <a-icon type="printer" />打印
      </a-button> -->
      <a-button slot="extra" type="primary" ghost @click="$router.go(-1)"> <a-icon type="left" />返回 </a-button>
      <section id="printContent">
        <a-spin :spinning="orderLoading">
          <img class="jsbarcode" id="jsbarcode" ref="jsbarcode" />
          <a-descriptions bordered>
            <a-descriptions-item label="入库通知单号">
              {{ receiptOrderItem.number }}
            </a-descriptions-item>
            <a-descriptions-item label="客户">
              {{ receiptOrderItem.client_name }}
            </a-descriptions-item>
            <a-descriptions-item label="仓库">
              {{ receiptOrderItem.warehouse_name }}
            </a-descriptions-item>
            <!-- <a-descriptions-item label="库区">
              {{ receiptOrderItem.area_name }}
            </a-descriptions-item> -->
            <a-descriptions-item label="创建人">
              {{ receiptOrderItem.creator_name }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ receiptOrderItem.create_time }}
            </a-descriptions-item>
            <a-descriptions-item label="客户单号">
              {{ receiptOrderItem.external_number }}
            </a-descriptions-item>
            <a-descriptions-item label="到货日期">
              {{ receiptOrderItem.arrival_date }}
            </a-descriptions-item>
            <a-descriptions-item label="总数量">
              {{ receiptOrderItem.total_quantity }}
            </a-descriptions-item>
            <a-descriptions-item label="备注" :span="3">
              {{ receiptOrderItem.remark }}
            </a-descriptions-item>
            <a-descriptions-item label="附件" :span="3">
              <a
                v-for="fileItem in receiptOrderItem.receipt_file_items"
                :href="fileItem.file"
                :key="fileItem.id"
                style="margin-right: 16px; display: block"
              >
                {{ fileItem.name }}
              </a>
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>

        <div style="margin-top: 16px">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="receiptMaterialItems"
            :loading="materialLoading"
            :pagination="false"
          >
            <div slot="material_weight" slot-scope="value, item">
              <span v-if="item.total_quantity && item.material_net_weight">
                {{ NP.times(item.total_quantity, item.material_net_weight, 0.001) }}
              </span>
            </div>
            <div slot="material_volume" slot-scope="value, item">
              <span v-if="item.total_quantity && item.material_volume">
                {{ NP.times(item.total_quantity, item.material_volume) }}
              </span>
            </div>
          </a-table>
        </div>
      </section>
    </a-card>
  </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import { receiptOrderRetrieve, receiptOrderMaterials } from "@/api/stockIn";
import { columns } from "./columns";
import NP from "number-precision";

export default {
  data() {
    return {
      NP,
      columns,
      orderLoading: false,
      materialLoading: false,
      receiptOrder: undefined,
      receiptOrderItem: {},
      receiptMaterialItems: [],
    };
  },
  computed: {
    totalWeight() {
      let totalWeight = 0;
      for (let item of this.receiptMaterialItems) {
        if (item.material_net_weight) {
          totalWeight = NP.plus(totalWeight, NP.times(item.total_quantity, item.material_net_weight, 0.001));
        }
      }
      return totalWeight;
    },
    totalVolume() {
      let totalVolume = 0;
      for (let item of this.receiptMaterialItems) {
        if (item.material_volume) {
          totalVolume = NP.plus(totalVolume, NP.times(item.total_quantity, item.material_volume));
        }
      }
      return totalVolume;
    },
  },
  methods: {
    initData() {
      this.receiptOrder = this.$route.query.id;
      document.getElementById("jsbarcode").html = "";
      this.orderLoading = true;
      receiptOrderRetrieve({ id: this.receiptOrder })
        .then((data) => {
          this.receiptOrderItem = data;
          var jsbarcode = new JsBarcode(this.$refs.jsbarcode, data.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
        })
        .finally(() => {
          this.orderLoading = false;
        });

      this.materialLoading = true;
      receiptOrderMaterials({ id: this.receiptOrder })
        .then((data) => {
          this.receiptMaterialItems = data;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style>
.jsbarcode {
  display: inline-block;
  float: right;
}
</style>
